import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue';
import About from '../views/About.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'About',
        component: About
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/leistungen',
        name: 'Leistungen',
        component: () => import('../views/Leistungen.vue')
    },
    {
        path: '/karriere',
        name: 'Karriere',
        component: () => import('../views/Karriere.vue')
    },
    {
        path: '/preise',
        name: 'Preise',
        component: () => import('../views/Prices.vue')
    },
    {
        path: '/kontakt',
        name: 'Kontakt',
        component: () => import('../views/Kontakt.vue')
    },
    {
        path: '/appointments',
        name: 'Termine',
        component: () => import('../views/Appointments.vue')
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: () => import('../views/Impressum.vue')
    },
    {
        path: '/datenschutz',
        name: 'Datenschutz',
        component: () => import('../views/Daten.vue')
    },
    // {
    //     path: '/verordnungsbedarf',
    //     name: 'Besonderer Verordnungsbedarf',
    //     component: () => import('../views/Verordnungsbedarf.vue')
    // },
    // {
    //     path: '/qr_landing/',
    //     name: 'QR Landing',
    //     component: () => import('../views/QRLanding.vue')
    // },
    {
        path: '/landing',
        name: 'Landing',
        component: () => import('../views/Landing.vue')
    },
    {
        path: '/apply',
        name: 'Apply',
        component: () => import('../views/ApplyForm.vue')
    },
    {
        path: '/blog',
        name: 'Blog',
        component: () => import('../views/Blog.vue')
    },
    {
        path: '/einrichtung-anmeldung',
        name: 'Einrichtung Anmeldung',
        component: () => import('../views/EinrichtungPatientenanmeldung.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: function (to) {
        if (to.hash) {
            return { selector: to.hash };
        }
        return { x: 0, y: 0 };
    },

    routes
});

export default router;
