<template>
  <div class="point-container my-2">
    <div class="icon-container">
      <i class="fas fa-2x text-primary bg-tertiary rounded-circle p-3" :class="icon"></i>
    </div>
    <div class="text-container">
      <div class="title-underlined text-primary fs-5">
        {{ title }}
      </div>
      <div class="text-white fs-6">
        - {{ first_point }}
      </div>
      <div class="text-white fs-6" v-show="second_point">
        - {{ second_point }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'RegistrationPoints',
    data() {
        return {
        };
    },
    props: {
        title: {
            type: String,
            required: true
        },
        first_point:
      {
          type: String,
          required: true
      },
        second_point:
      {
          type: String,
          required: false
      },
        icon:
      {
          type: String,
          required: false
      }
    }
};

</script>
<style scoped>
.point-container {
  background-color: var(--bs-background);
  color: var(--bs-text);
  padding: 10px 10px;
  border-radius: 50px;
  border: 2px solid var(--bs-text);
  display: flex;
  align-items: center;
  width: 100%;
}

.icon-container {
  flex-shrink: 0;
  margin-right: 20px;
}

.text-container {
  flex-grow: 1;
  text-align: center;
}

.title-underlined {
  text-decoration: underline;
  font-weight: bold;
}
</style>
