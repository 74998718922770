<template>
    <div>
        <div class="mb-5">
            <header-with-line header="Einrichtungs-Anmeldung" class="mb-4" />
            <div class="col-lg-9 mx-auto text-center">
                <h3 class="text-background">Bewohner einfach und schnell zur Therapie anmelden!</h3>
            </div>
        </div>
        <div class="container fs-4 text-justify">
          <p>
            Mit unserem Anmeldeformular kannst du Bewohner deiner Einrichtung unkompliziert
            zur Therapie bei uns anmelden. So haben wir alle wichtigen Infos direkt vorliegen
            und können eine passende Versorgung sicherstellen. Damit die Anmeldung reibungslos abläuft,
            stelle bitte sicher, dass die PDFs für den Datenschutz Bewohner und die Kontaktdaten
            des Betreuers vorab ausgefüllt und unterschrieben sind. Diese kannst du uns als Foto
            im Anmeldeformular hochladen. Für Folgetermine brauchst du das Formular nicht erneut
            auszufüllen – wir kümmern uns darum, dass alles nahtlos weiterläuft!
          </p>
        </div>
        <div class="container text-center">
          <div class="text-center mx-auto col-xl-6 col-lg-7 col-md-8 col-sm-10">
              <a href="/Datenschutz_Bewohner_Kontaktdaten_Betreuung.pdf" target="_blank" style="text-decoration: none;">
                <div class="text-white text-center bg-primary h4 reserve-box">
                  <span class="text-hover-download">Herunterladen:</span><br>
                  Datenschutz Bewohner mit<br>
                  Kontaktdaten Betreuung
                </div>
              </a>
          </div>
        </div>
        <div class="container fs-4 text-center">
            <b-card no-body class="overflow-hidden bg-transparent">
                <b-row class="d-flex justify-content-center align-items-center">
                    <b-col lg="5">
                        <img src="../../assets/img/facility.png" alt="lift" class="img-fluid img-card" />
                    </b-col>
                    <b-col lg="7">
                        <b-card-body>
                            <b-card-text class="text-justify">
                                <div>
                                    <div class="text-center mb-4">
                                        <img src="../../assets/img/decorations/how_you_register_residents_smoothly_for_initial_admission.svg"
                                            class="svg-title" />
                                    </div>
                                    <registration-points
                                        :title="'Vorbereitung der Dokumente'"
                                        :first_point="'Heilmittelverordnung Bewohner:in'"
                                        :second_point="'Datenschutz und Kontaktdaten Ausgefüllt '"
                                        :icon="'fas fa-file-circle-check'"
                                    />
                                    <registration-points
                                        :title="'Anmeldung starten'"
                                        :first_point="'Klicke auf „Zum Anmeldeformular“, um die Anmeldung zu beginnen.'"
                                        :icon="'fas fa-user-pen'"
                                    />
                                    <registration-points
                                        :title="'Angaben ausfüllen'"
                                        :first_point="'Trage die Bewohnerdaten, in das Formular ein.'"
                                        :icon="'fas fa-pen-to-square'"
                                    />
                                    <registration-points
                                        :title="'Dokumente hochladen'"
                                        :first_point="'Lade ein Foto der Verordnung sowie den Datenschutz & Kontaktdaten hoch.'"
                                        :icon="'fas fa-cloud-arrow-up'"
                                    />
                                    <registration-points
                                        :title="'Anmeldung abschließen'"
                                        :first_point="'Prüfe deine Angaben und sende das Formular ab.'"
                                        :icon="'fas fa-paper-plane'"
                                    />
                                </div>
                            </b-card-text>
                        </b-card-body>
                    </b-col>
                </b-row>
            </b-card>
            <appointment-reservation :text="'Zum Anmeldeformular'" :link="'/einrichtung-anmeldung'" />
        </div>
    </div>
</template>
<script>
import HeaderWithLine from '../Ui/HeaderWithLine.vue';
import AppointmentReservation from '../Ui/AppointmentReservation.vue';
import RegistrationPoints from '../Ui/RegistrationPoints.vue';

export default {
    name: 'YourAccessibility',
    components: {
        HeaderWithLine,
        AppointmentReservation,
        RegistrationPoints
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};
</script>
<style>
.img-card {
    border: 3px solid var(--bs-tertiary) !important;
    border-radius: 10px;
    max-height: 500px !important;
}

.svg-title {
    width: 100%;
}

.card {
    border: 0;
}

.title-text {
    border: 3px solid var(--bs-tertiary);
    border-radius: 10px;
}
</style>
