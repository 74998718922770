<template>
  <div>
    <div class="container pb-1">
        <HeaderWithLine header="Infos für dein Besuch" class="mb-4" />
        <div class="container fs-4 text-justify">
            <p>Um deinen ersten Termin so angenehm wie möglich zu gestalten,
              bitten wir dich, das <span class="text-bold">Datenschutzformular</span> vorab herunterzuladen, auszufüllen und uns per E-Mail oder als Foto über WhatsApp zu senden. So können wir uns beim ersten Treffen ganz auf deine Therapie konzentrieren und alles ist bestens vorbereitet. Falls es dir lieber ist, das Formular persönlich mitzubringen, bring es bitte unterschrieben zum Termin mit.</p>
        </div>
    </div>
    <div class="container text-center">
        <div class="text-center mx-auto col-xl-4 col-lg-5 col-md-6 col-sm-8">
            <a href="/Einwilligungserklärung.pdf" target="_blank" style="text-decoration: none;">
              <div class="text-white text-center bg-primary h4 reserve-box">
                Datenschutzformular<br>
                Herunterladen
              </div>
            </a>
        </div>
    </div>
    <div class="container fs-4 text-justify">
      <p>Für deinen ersten Besuch in unserem Therapiezentrum in Windsbach komm am besten etwa <span class="text-bold">10 Minuten früher</span>. Das gibt dir Zeit, an der Rezeption letzte Fragen zu klären und entspannt anzukommen. Damit du bestens vorbereitet bist, haben wir eine Checkliste erstellt – so hast du alles Wichtige für deinen Termin direkt zur Hand.</p>
    </div>
  </div>
</template>

<script>
import HeaderWithLine from '../Ui/HeaderWithLine.vue';

export default {
    name: 'YourVisit',
    components: {
        HeaderWithLine
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};

</script>
<style>
.color-lime {
  color: var(--bs-text);
}

.reserve-box {
  border-radius: 30px;
  border: 5px solid #b0c34a;
  padding: 30px;
  margin: 20px 0;
  cursor: pointer;
}

.reserve-box:hover {
  border: 5px solid #07425e;
  background-color: #b0c34a !important;
  text-decoration: none;
  transition: 0.3s;
}

.checklist-box {
  border-radius: 30px;
  background-color: #829caa;
  border: 5px solid #b0c34a;
  padding: 30px;
  margin: 20px 0;
}

.img-box {
  max-width: 35px;
}

.text-bold {
  font-weight: bold;
}

@media screen and (max-width: 576px){
  .img-box {
    min-width: 25px;
    max-width: 25px;
  }
}
</style>
