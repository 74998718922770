<template>
    <div>
        <h1>Anmeldeformular für Bewohner sozialer Einrichtungen</h1>
        <p>Für eine reibungslose Therapieplanung und vollständige Datenübermittlung</p>

        <form @submit.prevent="submitForm" enctype="multipart/form-data">
            <!-- 1. Einrichtung -->
            <h2>1. Einrichtung</h2>
            <div>
                <div class="mb-3">
                    <label for="yourName" class="form-label">Ihr Name und Position</label>
                    <input type="text" class="form-control" id="yourName" v-model="form.yourName" required />
                    <small class="form-text text-muted">
                        Als ausfüllende Person, z.B. Pflegekraft, Betreuungspersonal
                    </small>
                </div>
                <div class="mb-3">
                    <label for="facilityName" class="form-label">Name der Einrichtung</label>
                    <input type="text" class="form-control" id="facilityName" v-model="form.facilityName" required />
                </div>
            </div>

            <!-- 2. Bewohnerdaten -->
            <h2>2. Bewohnerdaten</h2>
            <div>
                <div class="mb-3">
                    <label for="salutation" class="form-label">Anrede</label>
                    <select class="form-select" id="salutation" v-model="form.salutation" required>
                        <option value="Frau">Frau</option>
                        <option value="Herr">Herr</option>
                    </select>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="firstName" class="form-label">Vorname</label>
                        <input type="text" class="form-control" id="firstName" v-model="form.firstName" required />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="lastName" class="form-label">Nachname</label>
                        <input type="text" class="form-control" id="lastName" v-model="form.lastName" required />
                    </div>
                </div>
                <div class="mb-3">
                    <label for="birthDate" class="form-label">Geburtsdatum</label>
                    <input type="date" class="form-control" id="birthDate" v-model="form.birthDate" required />
                </div>
                <div class="mb-3">
                    <label for="location" class="form-label">Stockwerk und Zimmernummer des Bewohners</label>
                    <input type="text" class="form-control" id="location" v-model="form.location" required />
                </div>
                <div class="mb-3">
                    <label for="residentPhoto" class="form-label">Foto des Bewohners (optional)</label>
                    <input type="file" class="form-control file-input pt-2" id="residentPhoto"
                        accept="image/*,application/pdf" capture="environment" @change="handleResidentPhotoUpload" />
                    <small class="form-text text-muted">
                        Zur schnellen Identifikation bei Vertretung
                    </small>
                    <!-- Bildvorschau -->
                    <div v-if="residentPhotoUrl" class="mt-3">
                        <img :src="residentPhotoUrl" alt="Foto des Bewohners"
                            style="max-width: 200px; max-height: 200px;" />
                    </div>
                </div>
            </div>

            <!-- 3. Kontaktinformation und Betreuung -->
            <h2>3. Kontaktinformation und Betreuung</h2>
            <div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="contactFirstName" class="form-label">Vorname Angehöriger / Betreuung</label>
                        <input type="text" class="form-control" id="contactFirstName" v-model="form.contactFirstName"
                            required />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="contactLastName" class="form-label">Nachname Angehöriger / Betreuung</label>
                        <input type="text" class="form-control" id="contactLastName" v-model="form.contactLastName"
                            required />
                    </div>
                </div>
                <div class="mb-3">
                    <label for="contactStreet" class="form-label">Straße und Hausnummer</label>
                    <input type="text" class="form-control" id="contactStreet" v-model="form.contactStreet" required />
                </div>
                <div class="row">
                    <div class="col-md-4 mb-3">
                        <label for="contactZip" class="form-label">PLZ</label>
                        <input type="text" class="form-control" id="contactZip" v-model="form.contactZip" required />
                    </div>
                    <div class="col-md-8 mb-3">
                        <label for="contactCity" class="form-label">Ort</label>
                        <input type="text" class="form-control" id="contactCity" v-model="form.contactCity" required />
                    </div>
                </div>
                <div class="mb-3">
                    <label for="contactPhone" class="form-label">Telefon</label>
                    <input type="tel" class="form-control" id="contactPhone" v-model="form.contactPhone" required />
                </div>
                <div class="mb-3">
                    <label for="contactEmail" class="form-label">E-Mail-Adresse Angehöriger / Betreuung</label>
                    <input type="email" class="form-control" id="contactEmail" v-model="form.contactEmail" required />
                    <small class="form-text text-muted">
                        Zur Anforderung von Zuzahlungen und für Abrechnungsinformationen
                    </small>
                </div>
                <div class="alert alert-info" role="alert">
                    Hinweis: Diese Kontaktinformation ist Voraussetzung für die
                    Therapieplanung. Ohne gültige E-Mail-Adresse zur Abrechnung und
                    Kommunikation kann keine Therapie gestartet werden.
                </div>
            </div>

            <!-- 4. Verordnung -->
            <h2>4. Verordnung</h2>

            <!-- Loop through the array of prescription items -->
            <div class="mb-3" v-for="(prescriptionItem, index) in form.prescriptions" :key="index">
                <label class="form-label">
                    Verordnung {{ index + 1 }} als Bild / PDF hochladen
                </label>

                <!-- File input for this specific prescription item -->
                <input type="file" class="form-control file-input pt-2" :id="'prescription' + index"
                    accept="image/*,application/pdf" capture="environment"
                    @change="handlePrescriptionUpload($event, index)" />

                <!-- Preview (if image) or filename (if PDF) -->
                <div v-if="prescriptionItem.preview" class="mt-3">
                    <div v-if="prescriptionItem.isImage">
                        <img :src="prescriptionItem.preview" alt="Vorschau der Verordnung"
                            style="max-width: 200px; max-height: 200px;" />
                    </div>
                    <div v-else>
                        <p>Datei hochgeladen: {{ prescriptionItem.file.name }}</p>
                    </div>
                </div>
            </div>

            <!-- Button to add another prescription file input -->
            <button type="button" class="btn btn-secondary mt-2" @click="addPrescriptionField">
                Weitere Datei hinzufügen
            </button>

            <!-- 5. Aufenthalts- und Abrechnungsinformationen -->
            <h2>5. Aufenthalts- und Abrechnungsinformationen</h2>
            <div class="mb-3">
                <label for="residencyStatus" class="form-label">Aufenthaltsstatus in der Einrichtung</label>
                <select class="form-select" id="residencyStatus" v-model="form.residencyStatus" required>
                    <option value="Dauerhafter Aufenthalt">Daueraufenthalt</option>
                    <option value="Kurzzeitpflege">Kurzzeitpflege</option>
                    <option value="Teilstationäre Pflege">Teilstationäre Pflege</option>
                </select>
            </div>

            <div v-if="form.residencyStatus === 'Kurzzeitpflege'" class="mb-3">
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="shortTermStartDate" class="form-label">Zeitraum von</label>
                        <input type="date" class="form-control" id="shortTermStartDate"
                            v-model="form.shortTermStartDate" required />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="shortTermEndDate" class="form-label">Zeitraum bis</label>
                        <input type="date" class="form-control" id="shortTermEndDate" v-model="form.shortTermEndDate"
                            required />
                    </div>
                </div>
            </div>

            <div v-else class="mb-3">
                <label for="permanentStartDate" class="form-label">Ab / seit wann</label>
                <input type="date" class="form-control" id="permanentStartDate" v-model="form.permanentStartDate"
                    required />
            </div>

            <div class="mb-3">
                <label for="coPaymentExemption" class="form-label">Zuzahlungsbefreiung</label>
                <select class="form-select" id="coPaymentExemption" v-model="form.coPaymentExemption">
                    <option value="Ja">Ja</option>
                    <option value="Nein">Nein</option>
                </select>
                <small class="form-text text-muted">(Information durch Pflegekraft genügt)</small>
            </div>

            <!-- 6. Einverständnis und Datenschutz -->
            <h2>6. Einverständnis und Datenschutz</h2>
            <div class="mb-3">
                <p>Datenschutzformular herunterladen und unterschreiben lassen</p>
                <a href="/Datenschutz_Bewohner_Kontaktdaten_Betreuung.pdf" target="_blank" class="btn btn-secondary">Datenschutzformular</a>
                <small class="form-text text-muted">
                    Zur rechtskonformen Einwilligung in die Datenverarbeitung des
                    Bewohners. Bitte je nach Fall durch Pflegepersonal oder
                    Betreuende/Angehörige unterschreiben lassen.
                </small>
            </div>

            <div class="mb-3">
                <label for="privacyAgreement" class="form-label">Unterschriebene Datenschutzerklärung hochladen
                    (optional)</label>
                <input type="file" class="form-control file-input pt-2" id="privacyAgreement"
                    accept="image/*,application/pdf" capture="environment" @change="handlePrivacyAgreementUpload" />
                <small class="form-text text-muted">
                    Bitte laden Sie die unterschriebene Datenschutzerklärung hoch.
                </small>
                <!-- Vorschau oder Dateiname -->
                <div v-if="privacyAgreementPreview" class="mt-3">
                    <div v-if="isPrivacyAgreementImage">
                        <img :src="privacyAgreementPreview" alt="Vorschau der Datenschutzerklärung"
                            style="max-width: 200px; max-height: 200px;" />
                    </div>
                    <div v-else>
                        <p>Datei hochgeladen: {{ form.privacyAgreement.name }}</p>
                    </div>
                </div>
            </div>

            <div class="alert alert-info" role="alert">
                Hinweis: Eine vollständige und genaue Anmeldung hilft uns, die Therapie
                bedarfsgerecht und effizient zu planen.
            </div>

            <!-- Absenden-Button mit Lade-Spinner -->
            <button type="submit" class="btn btn-primary" :disabled="loading">
                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-else>Formular absenden</span>
            </button>

            <!-- Erfolg- und Fehlermeldungen -->
            <div class="mt-3">
                <div class="alert alert-success" v-if="successMessage">
                    {{ successMessage }}
                </div>
                <div class="alert alert-danger" v-if="errorMessage">
                    {{ errorMessage }}
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from '../../services/axiosConfig';

export default {
    name: 'Anmeldeformular',
    data() {
        return {
            form: {
                yourName: '',
                facilityName: '',
                salutation: '',
                firstName: '',
                lastName: '',
                birthDate: '',
                location: '',
                residentPhoto: null,
                /* Array of prescription objects for multiple file uploads */
                prescriptions: [
                    {
                        file: null,
                        preview: null,
                        isImage: false
                    }
                ],
                contactFirstName: '',
                contactLastName: '',
                contactStreet: '',
                contactZip: '',
                contactCity: '',
                contactPhone: '',
                contactEmail: '',
                residencyStatus: '',
                shortTermStartDate: '',
                shortTermEndDate: '',
                permanentStartDate: '',
                coPaymentExemption: '',
                privacyAgreement: null
            },
            residentPhotoUrl: null,
            privacyAgreementPreview: null,
            isPrivacyAgreementImage: false,
            loading: false,
            successMessage: '',
            errorMessage: ''
        };
    },
    methods: {
        // Add a new empty prescription entry
        addPrescriptionField() {
            this.form.prescriptions.push({
                file: null,
                preview: null,
                isImage: false
            });
        },

        // Handle photo for the resident
        handleResidentPhotoUpload(event) {
            const [file] = event.target.files;
            this.form.residentPhoto = file;
            if (file) {
                this.residentPhotoUrl = URL.createObjectURL(file);
            } else {
                this.residentPhotoUrl = null;
            }
        },

        // Handle a new prescription file at a given index
        handlePrescriptionUpload(event, index) {
            const file = event.target.files[0];
            const prescriptionObj = this.form.prescriptions[index];

            if (file) {
                prescriptionObj.file = file;
                // Check if it's an image
                if (file.type.startsWith('image/')) {
                    prescriptionObj.isImage = true;
                    prescriptionObj.preview = URL.createObjectURL(file);
                } else {
                    // It's probably a PDF or other non-image
                    prescriptionObj.isImage = false;
                    prescriptionObj.preview = null;
                }
            } else {
                // If no file was selected
                prescriptionObj.file = null;
                prescriptionObj.preview = null;
                prescriptionObj.isImage = false;
            }
        },

        // Handle the optional privacy agreement upload
        handlePrivacyAgreementUpload(event) {
            const [file] = event.target.files;
            this.form.privacyAgreement = file;
            if (file) {
                const fileType = file.type;
                if (fileType.startsWith('image/')) {
                    this.isPrivacyAgreementImage = true;
                    this.privacyAgreementPreview = URL.createObjectURL(file);
                } else {
                    this.isPrivacyAgreementImage = false;
                    this.privacyAgreementPreview = null;
                }
            } else {
                this.privacyAgreementPreview = null;
                this.isPrivacyAgreementImage = false;
            }
        },

        async submitForm() {
            if (this.validateForm()) {
                this.loading = true;
                this.successMessage = '';
                this.errorMessage = '';

                const formData = new FormData();

                // Basic form fields
                formData.append('yourName', this.form.yourName);
                formData.append('facilityName', this.form.facilityName);
                formData.append('salutation', this.form.salutation);
                formData.append('firstName', this.form.firstName);
                formData.append('lastName', this.form.lastName);
                formData.append('birthDate', this.formatDate(this.form.birthDate));
                formData.append('location', this.form.location);

                // Contact info
                formData.append('contactFirstName', this.form.contactFirstName);
                formData.append('contactLastName', this.form.contactLastName);
                formData.append('contactStreet', this.form.contactStreet);
                formData.append('contactZip', this.form.contactZip);
                formData.append('contactCity', this.form.contactCity);
                formData.append('contactPhone', this.form.contactPhone);
                formData.append('contactEmail', this.form.contactEmail);

                // Residency info
                formData.append('residencyStatus', this.form.residencyStatus);
                formData.append('coPaymentExemption', this.form.coPaymentExemption);

                if (this.form.residencyStatus === 'Kurzzeitpflege') {
                    formData.append(
                        'shortTermStartDate',
                        this.formatDate(this.form.shortTermStartDate)
                    );
                    formData.append(
                        'shortTermEndDate',
                        this.formatDate(this.form.shortTermEndDate)
                    );
                } else {
                    formData.append(
                        'permanentStartDate',
                        this.formatDate(this.form.permanentStartDate)
                    );
                }

                // Resident photo
                if (this.form.residentPhoto) {
                    formData.append('residentPhoto', this.form.residentPhoto);
                }

                // Multiple prescriptions
                this.form.prescriptions.forEach((item, i) => {
                    if (item.file) {
                        // Each file appended with a unique key
                        formData.append(`prescriptions[${i}]`, item.file);
                    }
                });

                // Privacy agreement
                if (this.form.privacyAgreement) {
                    formData.append('privacyAgreement', this.form.privacyAgreement);
                }

                // Submit to your endpoint
                try {
                    const response = await axios.post('anmeldung/', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    if (response.data.status !== 1) {
                        this.errorMessage = response.data.message || 'Es gab ein Problem beim Senden des Formulars.';
                    } else {
                        this.successMessage = 'Ihre Anmeldung wurde erfolgreich übermittelt. Vielen Dank!';
                        this.resetForm();
                    }
                } catch (error) {
                    this.errorMessage = 'Es gab ein Problem beim Senden des Formulars. Bitte versuchen Sie es erneut.';
                } finally {
                    this.loading = false;
                }
            } else {
                this.errorMessage = 'Bitte füllen Sie alle Pflichtfelder aus.';
            }
        },

        validateForm() {
            // Basic validation
            if (!this.form.facilityName || !this.form.lastName || !this.form.location || !this.form.contactEmail) {
                return false;
            }

            if (this.form.residencyStatus === 'Kurzzeitpflege') {
                if (!this.form.shortTermStartDate || !this.form.shortTermEndDate) {
                    return false;
                }
            } else if (this.form.residencyStatus === 'Dauerhafter Aufenthalt') {
                if (!this.form.permanentStartDate) {
                    return false;
                }
            }

            return true;
        },

        resetForm() {
            // Reset the entire form to initial state
            this.form = {
                yourName: '',
                facilityName: '',
                salutation: '',
                firstName: '',
                lastName: '',
                birthDate: '',
                location: '',
                residentPhoto: null,
                prescriptions: [
                    {
                        file: null,
                        preview: null,
                        isImage: false
                    }
                ],
                contactFirstName: '',
                contactLastName: '',
                contactStreet: '',
                contactZip: '',
                contactCity: '',
                contactPhone: '',
                contactEmail: '',
                residencyStatus: '',
                shortTermStartDate: '',
                shortTermEndDate: '',
                permanentStartDate: '',
                coPaymentExemption: '',
                privacyAgreement: null
            };

            // Clear the previews
            this.residentPhotoUrl = null;
            this.privacyAgreementPreview = null;
            this.isPrivacyAgreementImage = false;

            // Keep any messages or loading flags consistent
            this.loading = false;
            this.errorMessage = '';
            // successMessage remains if you want to show it after reset
        },

        formatDate(date) {
            if (!date) return '';
            const d = new Date(date);
            const year = d.getFullYear();
            const month = `${d.getMonth() + 1}`.padStart(2, '0');
            const day = `${d.getDate()}`.padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
    }
};
</script>

<style scoped>
.anmeldeformular {
    margin-top: 20px;
}

h2 {
    margin-top: 30px;
}

.alert {
    margin-top: 20px;
}

button {
    margin-top: 20px;
}

/* Styling für Datei-Upload-Felder */
.file-input {
    display: flex;
    align-items: center;
}

.file-input::-webkit-file-upload-button {
    padding: 0.5em;
    border: none;
    background: #007bff;
    color: white;
    font-weight: bold;
    border-radius: 0.2em;
    cursor: pointer;
}

.file-input::-webkit-file-upload-button:hover {
    background: #0056b3;
}
</style>
